<template>
  <div class="w-full flex flex-col gap-10">
    <Wrapper class="max-w-2xl">
      <div class="text-left">
        <h2 class="text-lg font-bold">Respuesta a la queja radicada</h2>
        <p>Ingrese la información de su respuesta y soportes necesarios</p>
      </div>
      <ResponseForm />
    </Wrapper>
  </div>
</template>

<script>
import ResponseForm from '@/components/Dashboard/Complaints/Complaint/ResponseForm'

export default {
  components: {
    ResponseForm,
  },
}
</script>

<style></style>
